import React, { Component } from "react";
import { translations } from "../../../../translate";
import { Col, Row, Modal, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
import { confirmAlert } from "react-confirm-alert";

let Strings =
  localStorage.getItem("LANG") == 1 ? translations.Ar : translations.En;

export default class AddBillForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitError: "",
      loading: 0,
      errMessage: "",
      billType: "",
      amount: 0,
      billTypes: this.props.billTypes,
      posId: null,
      posCode: "",
      notes: "",
    };
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      submitError: "",
    });
  };
  setUpSelectOptions = (data, type) => {
    if (type == "currency") {
      return (
        <option value={data.id} key={data.id}>
          {data.currency}
        </option>
      );
    } else if (type == "timeZone") {
      return (
        <option value={data.id} key={data.id}>
          {data.name}
        </option>
      );
    } else if (type == "dists") {
      return (
        <option value={data.userId} key={data.id}>
          {data.fname}
        </option>
      );
    }
  };
  handleChangeSelect = (type, option) => {
    console.log(option);
    this.setState(
      (state) => {
        return {
          [type]: option,
        };
      },
      () => {}
    );
  };
  handleValidSubmit = async (event) => {
    event.preventDefault();
    try {
      this.setState({ loadingSave: 1, submitError: "", posError: 0 });
      const body = {
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        billType: this.props.balance || this.state.billType,
        amount: this.state.amount,
        notes: this.state.notes,
      };
      if ((localStorage.getItem("POS_MGR_EMP_NEW") == 1|| localStorage.getItem("USER_LEVEL") == 18)) {
        body.type = "sendBillRequestSubPosToPos";
        if (this.state.posId == null) {
          this.setState({ posError: 1 });
          return;
        }
        body.posId = this.state.posId?.permId;
      } else {
        body.type = "sendBillRequestPosToSubPos";
      }
      if (this.state.amount < 0) return;
      if (this.state.billType == "" && !this.props.balance) return;
      this.setState({ modal: true });
      return;
      const response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0 || data["error"]) {
            this.setState({ submitError: data["errorMsg"], loadingSave: 0 });
          } else {
            this.setState({ loadingSave: 0 });
            this.props.close();
            this.props.getSupervisorBalance();
          }
        }
      }
    } catch (error) {
      this.setState({ submitError: error.message });
    } finally {
      this.setState({ loadingSave: 0 });
    }
  };
  errorModal = (msg) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <div className="mb-3">
              <span className="alert-delete-icon">!</span>
            </div>
            <h5>{msg}</h5>
            <button
              type="button"
              className="btn form-cancel-btn alert-btn-margin ml-2 mr-2"
              onClick={onClose}
            >
              {Strings.Cancel}
            </button>
          </div>
        );
      },
    });
  };
  submit = async () => {
    try {
      this.setState({ loadingSave: 1, submitError: "", posError: 0 });
      const body = {
        lang: localStorage.getItem("LANG"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        billType: this.props.balance || this.state.billType,
        amount: this.state.amount,
        notes: this.state.notes,
      };
      if ((localStorage.getItem("POS_MGR_EMP_NEW") == 1|| localStorage.getItem("USER_LEVEL") == 18)) {
        body.type = "sendBillRequestSubPosToPos";
        if (this.state.posId == null) {
          this.setState({ posError: 1 });
          return;
        }
        body.posId = this.state.posId?.permId;
      } else {
        body.type = "sendBillRequestPosToSubPos";
      }
      if (this.state.amount < 1) return;
      if (this.state.billType == "" && !this.props.balance) return;

      const response = await fetch(window.API_PERM_USER, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data !== "Nothing found") {
          if (data["logout"] == 1) {
            localStorage.setItem("USER_ID", "");
            localStorage.setItem("USER_TOKEN", "");
            window.location.href = "/logout";
          } else if (data["error"] != 0 || data["error"]) {
            this.setState({ submitError: data["errorMsg"], loadingSave: 0 });
          } else {
            this.setState({ loadingSave: 0 });
            this.errorModal(
              data["errorMsg"].split("\\n").join("\n") ||
                Strings.doneSucessfully
            );
            this.props.close();
            this.props.getSupervisorBalance();
          }
        }
      }
    } catch (error) {
      this.setState({ submitError: error.message });
    } finally {
      this.setState({ loadingSave: 0, modal: false });
    }
  };
  render() {
    return (
      <div className="view-modal-style">
        <Modal
          isOpen={this.state.modal}
          centered={true}
          className={"requestdataModal"}
        >
          <div
            className="close-modal-btn "
            onClick={() => {
              this.setState({ posCode: "", modal: false, modalData: null });
            }}
          >
            X
          </div>
          <ModalBody className="d-flex align-items-center justify-content-center gap-3">
            {/* <AvForm> */}
            <div className="d-flex flex-column justify-content-between align-items-center h-100">
              <h5 className="text-center">{`${Strings["confirmPayment"]} (${this
                .state.amount || 0})`}</h5>
              <div></div>
              {this.state.loadingSave == 1 ? (
                <button
                  type="button"
                  className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"
                >
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Confirm"]}
                </button>
              ) : (
                <button
                  type={"button"}
                  className={
                    "btn gradient-button-view gradient-button gradient-button-font-12 ml-1 f-15 gradient-button-view-mobile "
                  }
                  onClick={(event) => {
                    event.preventDefault();
                    this.submit();
                  }}
                >
                  {Strings["Confirm"]}
                </button>
              )}

              {this.state.posCodeError != "" &&
                this.state.posCodeError != undefined && (
                  <div className="error-text-red font-size-15 text-center mt-3">
                    {this.state.posCodeError}
                  </div>
                )}
            </div>
            {/* </AvForm> */}
          </ModalBody>
        </Modal>
        <div className="sidebar-header-view">
          <Row>
            <Col lg={10} md={10} sm={10} xs={10}>
              <div className="p-0 main-color-1  sidebar-header-title">
                {" "}
                {Strings["Add a Bill"]}
              </div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2} className="text-right">
              <HighlightOffIcon
                onClick={this.props.close}
                className="modal-close-icon"
              />
            </Col>
          </Row>
        </div>
        <div className="sidebar-body-view">
          {this.state.loading == 1 && (
            <Row>
              <Col md={12} style={{ marginTop: "15%" }} className="text-center">
                <div className="card-body">
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-1"
                    role="status"
                  ></div>
                  <div
                    className="spinner-grow text-dark m-2 main-color-2"
                    role="status"
                  ></div>
                </div>
              </Col>
            </Row>
          )}
          {this.state.loading != 1 && (
            <AvForm onSubmit={this.handleValidSubmit}>
              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1|| localStorage.getItem("USER_LEVEL") == 18) && (
                <div className="mb-4">
                  <label
                    className={this.state.posError == 1 ? "label-error" : ""}
                  >
                    {Strings["POS"]} *
                  </label>
                  <Picky
                    value={this.state.posId}
                    options={this.props.posList}
                    onChange={this.handleChangeSelect.bind(this, "posId")}
                    open={false}
                    valueKey={
                      localStorage.getItem("USER_LEVEL") == 1 ||
                      localStorage.getItem("PERM_TYPE") == 5
                        ? "userId"
                        : "permId"
                    }
                    labelKey={
                      localStorage.getItem("USER_LEVEL") == 1 ||
                      localStorage.getItem("PERM_TYPE") == 5
                        ? "fname"
                        : "name"
                    }
                    multiple={false}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    placeholder={Strings["POS"]}
                    filterPlaceholder={Strings["Select POS"]}
                    className={
                      this.state.posError == 1 ? "border border-danger" : ""
                    }
                  />
                  {this.state.posError == 1 && (
                    <div className="msg-error">
                      {Strings["This field is required"]}
                    </div>
                  )}{" "}
                </div>
              )}
              {!this.props.balance && (
                <AvField
                  type="select"
                  name="billType"
                  required
                  errorMessage={Strings["This field is required"]}
                  label={Strings["Invoice Type"] + " *"}
                  value={this.state.billType}
                  onChange={this.handleInputChange}
                  className="p-1 pl-3 mt-2"
                >
                  <option
                    style={{
                      display: "none",
                    }}
                    value=""
                    selected="selected"
                  >
                    {Strings["Invoice Type"]}
                  </option>
                  {this.state.billTypes?.map((data, index) => (
                    <option value={data?.key} key={index}>
                      {data?.value}
                    </option>
                  ))}
                </AvField>
              )}
              <AvField
                name="amount"
                type="number"
                label={Strings["Amount"]}
                placeholder={Strings["Amount"]}
                value={this.state.amount}
                onChange={this.handleInputChange}
                errorMessage={Strings["This field is required"]}
                required
                min="0"
              />
              {(localStorage.getItem("POS_MGR_EMP_NEW") == 1|| localStorage.getItem("USER_LEVEL") == 18) && (
                <AvField
                  type="textarea"
                  name="notes"
                  label={Strings["Notes"]}
                  placeholder={Strings["Enter"] + " " + Strings["Notes"]}
                  value={this.state.notes}
                  onChange={this.handleInputChange}
                />
              )}
              {this.state.loadingSave == 1 ? (
                <button
                  type="button"
                  className="btn form-save-btn mr-1 ml-1 task-action-btn-loading"
                >
                  <CircularProgress className="SaveCircularProgressColor" />{" "}
                  {Strings["Save"]}
                </button>
              ) : (
                <button type="submit" className="btn form-save-btn mr-1 ml-1">
                  {" "}
                  {Strings["Save"]}{" "}
                </button>
              )}
              {this.state.submitError && (
                <div className="error-text-black mt-3">
                  <i className="flaticon2-exclamation error-icon mr-1" />{" "}
                  {this.state.submitError}
                </div>
              )}
            </AvForm>
          )}
        </div>
      </div>
    );
  }
}
